<template>
  <v-card flat class="rounded-t-0">
    <v-list dense class="py-0" expand>
      <v-system-bar> diese Woche noch anstehend </v-system-bar>

      <v-list-item
        v-for="item in itemsNow"
        :key="item.id"
        @click="select(item)"
        :class="isCurrent(item) ? 'current disabled' : 'non-current'"
      >
        <v-list-item-action class="mr-4">
          <v-icon color="success" :disabled="isPast(item)">{{
            subjectIcon(item.course.subject)
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ formatDayOfWeek(item.date) }}, {{ item.period.startTime }}
          </v-list-item-subtitle>
          <v-list-item-title
            >{{ item.course.subject.code }}: {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="item.url">
          <v-btn icon :href="item.url" target="_blank">
            <v-icon>mdi-link</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="itemsNow.length == 0">
        <v-list-item-action class="mr-4">
          <v-icon>mdi-close</v-icon>
        </v-list-item-action>
        <v-list-item-subtitle>nichts eingetragen</v-list-item-subtitle>
      </v-list-item>

      <v-system-bar> nächste Woche</v-system-bar>
      <v-list-item
        v-for="item in itemsNext"
        :key="item.id"
        @click="select(item)"
      >
        <v-list-item-action class="mr-4">
          <v-icon color="success">{{
            subjectIcon(item.course.subject)
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ formatDayOfWeek(item.date) }}, {{ item.period.startTime }}
          </v-list-item-subtitle>
          <v-list-item-title
            >{{ item.course.subject.code }}: {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="item.url">
          <v-btn icon :href="item.url" target="_blank">
            <v-icon>mdi-link</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="itemsNext.length == 0">
        <v-list-item-action class="mr-4">
          <v-icon>mdi-close</v-icon>
        </v-list-item-action>
        <v-list-item-subtitle>nichts eingetragen</v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <v-bottom-sheet
      min-width="350px"
      max-width="500px"
      v-model="dialog"
      :close-on-content-click="false"
    >
      <NoteSheet :value="selectedItem" @close="dialog = false" />
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { addDays, findMonday, formatDayOfWeek, today } from "common/utils/date";
import { subjectIcon } from "common/utils/icons";

import NoteSheet from "common/components/NoteSheet";

export default defineComponent({
  components: {
    NoteSheet,
  },
  data() {
    return {
      itemsNow: [],
      itemsNext: [],
      loading: false,
      selectedItem: {},
      dialog: false,
    };
  },
  methods: {
    subjectIcon,
    formatDayOfWeek,
    isCurrent(item) {
      const time = new Date(item.date + "T" + item.period.startTime);
      const now = new Date();
      return (
        time.getTime() > now.getTime() - 45 * 60 * 1000 &&
        time.getTime() < now.getTime() + 10 * 60 * 1000
      );
    },
    isPast(item) {
      const time = new Date(item.date + "T" + item.period.startTime);
      const now = new Date();
      return time.getTime() < now.getTime() - 45 * 60 * 1000;
    },
    async fetchData() {
      this.loading = true;
      const startDateNow = today();
      const monday = findMonday(startDateNow);
      const endDateNow = addDays(monday, 6);
      const startDateNext = addDays(monday, 7);
      const endDateNext = addDays(startDateNext, 6);
      this.itemsNow = await this.apiList({
        resource: "register/note",
        query: `person=${this.$_profilePerson.id}&type=homework&startDate=${startDateNow}&endDate=${endDateNow}`,
      });
      this.itemsNext = await this.apiList({
        resource: "register/note",
        query: `person=${this.$_profilePerson.id}&type=homework&startDate=${startDateNext}&endDate=${endDateNext}`,
      });
      this.loading = false;
      this.$emit("loaded");
    },
    select(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
<style scoped>
.current {
  border-left: 3px solid #ea4335;
}
.non-current {
  border-left: 3px solid transparent;
}
</style>
